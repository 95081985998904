import { useRef, useState } from 'react';
import axios from 'axios'
import { Alert, Box, Button, Checkbox, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, MenuItem, Select, TextField, Typography } from '@mui/material'
// import { styled } from '@mui/material/styles';
import SignatureCanvas from 'react-signature-canvas'
import mdrLogo from './mdr-logo.png'
import './App.css';

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

function App() {
  const sigCanvas = useRef({})
  const dateCanvas = useRef({})
  const [formData, setFormData] = useState(null)
  const [submitResponse, setSubmitResponse] = useState(null)
  const [sendingOpen, setSendingOpen] = useState(false)
  const [legalRep, setLegalRep] = useState(false)

  function updateFormData(e) {
    let target = e.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    let name = target.id;

    setFormData({ ...formData, [name]: value })
  }

  function repChange(e) {
    if (e.target.checked) {
      setLegalRep(true)
    } else {
      setLegalRep(false)
    }
  }

  function updateSig(e) {
    setFormData({ ...formData, wetSignature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png") })
  }

  function updateDate(e) {
    setFormData({ ...formData, wetDate: dateCanvas.current.getTrimmedCanvas().toDataURL("image/png") })
  }

  function clearSig(e) {
    sigCanvas.current.clear()
  }

  function clearDate(e) {
    dateCanvas.current.clear()
  }

  function sendingClose() { }

  function submitCOP(e) {
    setSendingOpen(true);
    e.preventDefault();
    // console.log(formData)
    axios({
      url: 'https://alpha.api.mdr.net/ws/patients/newCOPv2',
      method: 'POST',
      data: formData,
      auth: {
        username: formData.sig,
        password: formData.sig,
      }
    }).then(() => {
      setSubmitResponse("Success!")
      // console.log("success")
    }).catch(err => {
      // console.log(err.response.data.error)
    })
  }

  return (
    <Container className="App" sx={{ background: '#f9090a', borderRadius: '0.5em', px: 1 }} maxWidth='xl'>
      <Dialog onClose={sendingClose} open={sendingOpen}>
        <DialogTitle>
          {submitResponse ? submitResponse : 'Sending...'}
        </DialogTitle>
        <DialogContent>
          {submitResponse ? "You're done" : 'This usually take just a couple seconds.'}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => document.location.href = "https://mdr.net"}>Go to https://mdr.net/?</Button>
          <Button autoFocus onClick={() => setSendingOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ background: 'white', borderRadius: '0 0 0.5em 0.5em', alignItems: 'center', textAlign: 'center', p: 1, gap: 1 }}>
        <Typography variant='h3' sx={{ color: 'navy' }}>
          Welcome To
        </Typography>
        <img src={mdrLogo} style={{ width: '95%' }} alt="Medical Resources Inc Logo" />
        <Typography>
          Medical Resources' respects your right to choose your Home Medical Equipment provider. There is nothing that makes us more proud than being able to provide everything you or your child needs, while also meeting your every expectation as your provider.
        </Typography>
        <Divider variant="middle">
          <Chip label="Notes before proceeding" />
        </Divider>
        <Alert severity='warning'>Medical Resources Inc recommends holding onto your current equipment and supply until your next provider has equipment and supply ready to replace the previous providers.</Alert>
        <Alert severity='warning'>
          For the best experience we recommend using this site on a touch device, like a iphone or android in landscape mode. it makes signing at the bottom a lot easier. -Steven
        </Alert>
      </Box>
      <Divider />
      <form onChange={updateFormData} style={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ background: 'white', alignItems: 'center', borderRadius: '0.5em 0.5em 0 0', p: 1 }}>
          <Divider variant="middle" >
            <Chip label="Patient Demographics" component={Typography} sx={{ fontSize: "1.17em", fontWeight: 'bold', mt: 1, mb: 1, ml: 0, mr: 0 }} />
          </Divider>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id='patientFirstName' label="First Name" />
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id='patientLastName' label="Last Name" />
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id='patientDateOfBirth' label="Date of Birth" type="date" />
          </Box>
        </Box>
        <Box sx={{ background: 'white', px: 1 }}>
          <Divider variant="middle" >
            <Chip label="Patient Address" component={Typography} sx={{ fontSize: "1.17em", fontWeight: 'bold', mt: 1, mb: 1, ml: 0, mr: 0 }} />
          </Divider>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="patientStreetOne" label="Street" />
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="patientStreetTwo" label="Street Two" />
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="patientCity" label="City" />
            <Select id="patientAddressState" label="State" value="Alabama">
              <MenuItem value="Alabama">Alabama</MenuItem>
              <MenuItem value="disabled" disabled>We only accept Alabama patients</MenuItem>
            </Select>
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="patientZip" label="Zip" />
          </Box>
        </Box>
        <Box sx={{ background: 'white', px: 1 }}>
          <Divider variant="middle" >
            <Chip label="Caregiver Information" component={Typography} sx={{ fontSize: "1.17em", fontWeight: 'bold', mt: 1, mb: 1, ml: 0, mr: 0 }} />
          </Divider>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="caregiverName" label="Name" />
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="caregiverPhoneNumber" label="Phone Number" />
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="caregiverEmail" label="Email" />
          </Box>
        </Box>
        <Box sx={{ background: 'white', px: 1 }}>
          <Divider variant="middle" >
            <Chip label="Primary (Pediatrician) Doctor" component={Typography} sx={{ fontSize: "1.17em", fontWeight: 'bold', mt: 1, mb: 1, ml: 0, mr: 0 }} />
          </Divider>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="primaryDoctorName" label="Name" />
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="primaryDoctorPhoneNumber" label="Phone Number" />
          </Box>
        </Box>
        <Box sx={{ background: 'white', px: 1 }}>
          <Divider variant="middle" >
            <Chip label="Ordering (Specialist) Doctor" component={Typography} sx={{ fontSize: "1.17em", fontWeight: 'bold', mt: 1, mb: 1, ml: 0, mr: 0 }} />
          </Divider>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="physicianName" label="Name" />
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="physicianPhoneNumber" label="Phone Number" />
          </Box>
        </Box>
        <Box sx={{ background: 'white', px: 1 }}>
          <Divider variant="middle" >
            <Chip label="Insurance Information" component={Typography} sx={{ fontSize: "1.17em", fontWeight: 'bold', mt: 1, mb: 1, ml: 0, mr: 0 }} />
          </Divider>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="insurancePrimaryName" label="Primary Insurance" />
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="insurancePrimaryPolicyNumber" label="Primary Policy Number" />
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="insuranceSecondaryName" label="Secondary Insurance" />
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="insuranceSecondaryPolicyNumber" label="Secondary Policy Number" />
          </Box>
        </Box>
        <Box sx={{ background: 'white', px: 1 }}>
          <Divider variant="middle" >
            <Chip label="Last Provider Information" component={Typography} sx={{ fontSize: "1.17em", fontWeight: 'bold', mt: 1, mb: 1, ml: 0, mr: 0 }} />
          </Divider>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="lastProviderName" label="Name" />
            <TextField helperText=" " InputLabelProps={{ shrink: true }} id="lastProviderPhoneNumber" label="Phone Number" />
          </Box>
        </Box>
        <Box sx={{ background: 'white', borderRadius: '0 0 0.5em 0.5em', px: 1 }}>
          <Divider variant="middle" >
            <Chip label="Equipment" component={Typography} sx={{ fontSize: "1.17em", fontWeight: 'bold', mt: 1, mb: 1, ml: 0, mr: 0 }} />
          </Divider>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
            <Typography>
              Use the check boxes below to let us know what equipment you currently are using.
            </Typography>
            <FormGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <FormControlLabel control={<Checkbox id="equipmentVentilator" />} label="Ventilator" />
              <FormControlLabel control={<Checkbox id="equipmentBivona" />} label="Bivona" />
              <FormControlLabel control={<Checkbox id="equipmentNebulizer" />} label="Nebulizer" />
              <FormControlLabel control={<Checkbox id="equipmentCPAP" />} label="CPAP" />
              <FormControlLabel control={<Checkbox id="equipmentApnea Monitor" />} label="Apnea Monitor" />
              <FormControlLabel control={<Checkbox id="equipmentSupplemental Oxygen" />} label="Supplemental Oxygen" />
              <FormControlLabel control={<Checkbox id="equipmentTracheostomy" />} label="Tracheostomy" />
              <FormControlLabel control={<Checkbox id="equipmentSuctionMachine" />} label="Suction Machine" />
              <FormControlLabel control={<Checkbox id="equipmentEnteral" />} label="Enteral" />
              <FormControlLabel control={<Checkbox id="equipmentCough Assist Device" />} label="Cough Assist Device" />
              <FormControlLabel control={<Checkbox id="equipmentBiPAP" />} label="BiPAP" />
              <FormControlLabel control={<Checkbox id="equipmentOximeter" />} label="Oximeter" />
            </FormGroup>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ background: 'white', borderRadius: '0.5em', px: 1, alignItems: 'center' }}>
          <Divider variant="middle" >
            <Chip label="Patient Change of Provider" component={Typography} sx={{ fontSize: "1.17em", fontWeight: 'bold', mt: 1, mb: 1, ml: 0, mr: 0 }} />
          </Divider>
          <Typography variant='body2'>By my signature below, I am exercising my right to choose a medical equipment provider of my choice and have chosen Medical Resources to provide the items/services selected above. I request that my current medical equipment provider:</Typography>
          <ul>
            <li><Typography variant='body2'>Respect my right to choose by facilitating and not impending my change of provider.</Typography></li>
            <li><Typography variant='body2'>Continue to provider my equipment and services, without interruption, until I confirm a date to cease and stop services.</Typography></li>
            <li><Typography variant='body2'>Release any health information requested by Medical Resources within seven days.</Typography></li>
          </ul>
          <Typography variant='body2'>I understand that:</Typography>
          <ul>
            <li><Typography variant='body2'>Medical Resources may accept assignment from my Primary or Secondary Payer only if it receives accurate and complete information from my current medical equipment provider, including but not limited to, physician prescription, medical necessity documentation, and discontinuation/expiration of current provider's current equipment/billing cycles for items list.</Typography></li>
            <li><Typography variant='body2'>Other terms and conditions apply and will be described to me at or before time of delivery.</Typography></li>
          </ul>
          <Typography variant='body2'>Medical Resources:</Typography>
          <Typography variant='body2'>(Telephone) +1 (205) 521-7337 ext 222 or (Fax) 205 521 0585 or (Email) wecare@mdr.net</Typography>
          <Divider variant="middle" >
            <Chip label="Authorization for " component={Typography} sx={{ fontSize: "1.17em", fontWeight: 'bold', mt: 2, mb: 0, ml: 0, mr: 0 }} />
          </Divider>
          <Divider variant="middle" >
            <Chip label="Release of Medical Records" component={Typography} sx={{ fontSize: "1.17em", fontWeight: 'bold', mt: 0, mb: 2, ml: 0, mr: 0 }} />
          </Divider>
          <Typography variant='body2'>By my signature below, I authorize any physician, durable medical equipment supplier, or other health care provider, past or present, who provided me with health care to release my health information to Medical Resources, within seven days of this authorization, for the purpose of treatment and continuity of care. In addition to any information Medical Resources may request, I specifically authorize the release of the following: Physician Order, Medicaid 360, EPSDT, Prior Authorizations, CMN, Medical Necessity Documents and Billing Begin/End dates.</Typography>
          <Typography variant='body2'>I understand that I do not have to sign this authorization and my care is not conditioned on signing this authorization. I understand that the information used or disclosed pursuant to this authorization may be subject to redisclosure by the recipient and no longer be protected by federal regulations regarding privacy of health information.</Typography>
          <Typography variant='body2'>This authorization will be valid unless and until I revoke it. I have the right to revoke this authorization at any time by delivering or sending written notice of revocation to Medical Resources at 1516 5 th Ave. S, Birmingham, AL 35233, or fax to 205-521-0585. Written revocation will be effective as soon as it is received by Medical Resources, except to the extent that Medical Resources has taken action in reliance on the authorization.</Typography>
          <Typography variant='body2'>If this authorization is signed by a person other than the patient, then the person signing attests to the following:</Typography>
          <ul>
            <li><Typography variant='body2'>The person signing is the personal representative of the patient</Typography></li>
            <li><Typography variant='body2'>The person signing has legal authority (e.g., guardianship, parent, etc.) to sign on behalf of the patient.</Typography></li>
          </ul>
        </Box>
        <Divider />
        <Box sx={{ background: 'white', px: 1, borderRadius: '0.5em' }}>
          <FormControlLabel label="Are you a personal representative signing on behave of the patient?" control={<Checkbox onChange={repChange} />} />
          <Box sx={{ display: legalRep ? 'flex' : 'none', flexDirection: 'row', gap: 1, flexWrap: 'wrap', justifyContent: 'center' }}>
            <TextField id="legalRepName" helperText=" " InputLabelProps={{ shrink: true }} placeholder="John Doe" label="Representative's Name" />
            <TextField id="legalRepContact" helperText=" " InputLabelProps={{ shrink: true }} placeholder="Phone / Email / Fax / etc" label="Representative's Contacts" />
            <TextField id="legalRepAuthority" helperText=" " InputLabelProps={{ shrink: true }} placeholder=" Parent / Guardian / etc" label="Representative's Legal Authority" />
          </Box>
          <Typography></Typography>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography variant='h6'>Please sign in the box below</Typography>
            <SignatureCanvas ref={sigCanvas} canvasProps={{ width: 350, height: 200, style: { backgroundColor: 'lightgrey' } }} onEnd={updateSig} />
            <Button onClick={clearSig}>Clear</Button>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography variant='h6'>Please date in the box below</Typography>
            <SignatureCanvas ref={dateCanvas} canvasProps={{ width: 350, height: 200, style: { backgroundColor: 'lightgrey' } }} onEnd={updateDate} />
            <Button onClick={clearDate}>Clear</Button>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ background: 'white', pb: 1, borderRadius: '0.5em 0.5em 0 0', p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography>
            That is it! Click the submit button below. :D
          </Typography>
          <Button variant='outlined' onClick={submitCOP}>Submit</Button>
        </Box>
      </form>
    </Container>
  );
}

export default App;
